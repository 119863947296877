.school-about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 1rem;

  &__section {
    width: 100%;
    color: #1a2a45;
    text-align: left;
    background-color: #ffffff;
    border: 1px solid #f3f5f7;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0px 0px 1px 0px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    line-height: 1.3;
    p {
      line-height: 1.3;
      margin-bottom: 1rem;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 8px;
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
  }

  &__video {
    width: 100%;
    height: auto;

    iframe {
      width: 100%;
      height: 400px;
      border: none;
      border-radius: 8px;
    }
  }
}
